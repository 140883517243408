@import "~src/styles/_custom";

.MenuIcon {
  margin-left: 0.25rem;
  :global(path) {
    fill: white;
  }
  &:focus {
    outline: none;
  }
  &_Disabled {
    pointer-events: none;
  }
}
