@import "~src/styles/_custom";

// Colors
$bg-color: #1370fb;
$dot-color: white;

// Dimensions
$dot-size: 2px;
$dot-space: 5px;

.HeadColumn {
  height: 100%;
  display: flex;
  align-items: center;
  font-weight: bold;
  justify-content: center;
  background-color: #f4f8fb;

  &_Clickable {
    cursor: pointer;
    &:hover {
      background-color: #e2e6ea;
    }
  }
}

.ScheduleStatusContent {
  text-align: center;
  p {
    font-size: 0.75rem;
    margin: 0 0;
  }
}
.ScheduleStatusIcon {
  padding-left: 0.5rem;
  :global(svg) {
    transform: translateY(-2px);
    path {
      fill: $blue;
    }
  }
}

.ScheduleStatusLabel {
  margin: 0 0;
  text-transform: uppercase;
  font-weight: bolder;
  font-size: 0.875rem;
  letter-spacing: 1px;
  color: $blue;
}

.ScheduleStatus {
  display: flex;
  align-items: center;
  padding: 0.25rem 1rem;
  min-height: 42px;

  border-radius: 100px;
  background-color: $gray-100;

  &_Button {
    cursor: pointer;
    &:hover {
      background-color: $gray-200;
    }
  }

  &_Published {
    .ScheduleStatusLabel {
      color: $primary;
    }

    .ScheduleStatusIcon {
      :global(svg) {
        path {
          fill: $primary;
        }
      }
    }
  }
  &_Unpublished {
    .ScheduleStatusLabel {
      color: $gray-500;
    }
  }
}

.ResourceHeadColumn {
  font-weight: bold;
  height: 100%;
}

.Resource {
  height: 100%;
  font-weight: bold;
  padding: 0.875rem;
  position: relative;

  &Name {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 0.5rem;

    :global(h5) {
      font-size: 14px;
      margin: 0 0 0 0.5rem;
    }
  }

  &Details {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  &Hours {
    font-weight: bolder;
    font-size: 0.625rem;
  }
}

.RoleBadge {
  font-size: 0.625rem;
}

.EventContainer {
  cursor: pointer;
  font-size: 0.75rem;
  margin: 0 5px 0.25rem 5px;
  background-color: $blue;
  height: calc(100% - 0.5rem);
  padding: 0.125rem 1rem;
  border-radius: 6px;
  font-weight: 900;
  color: white;
  overflow: hidden;
  white-space: nowrap;
  box-shadow: $box-shadow-sm;
  transition: box-shadow 0.2s;

  &_Timeoff {
    background-image: linear-gradient(
      135deg,
      #ebebeb 25%,
      #f5f5f5 25%,
      #f5f5f5 50%,
      #ebebeb 50%,
      #ebebeb 75%,
      #f5f5f5 75%,
      #f5f5f5 100%
    );
    background-size: 16.97px 16.97px;
    color: $gray-900;
  }

  &_OpenShift {
    background-color: $gray-100;
    color: $gray-900;
  }

  &_ClipEnd {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  &_ClipStart {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.CellWrapper {
  height: 100%;
  position: relative;
  transition: background-color 0.2s ease-in-out;

  &_Active {
    background-color: #deeffd;
  }
}

.EventDragHandle {
  cursor: ew-resize;
  width: 16px;
  height: 35px;
  position: absolute;
  top: 6px;
  border-radius: 4px;

  &:hover {
    :global(svg g) {
      fill: #00000080;
    }
  }

  &_Left {
    left: 4px;
  }

  &_Right {
    right: 4px;
  }

  :global(svg) {
    transform: translate(-50%, -50%);
    left: 50%;
    position: absolute;
    top: 50%;

    g {
      fill: #00000025;
    }
  }
}

.SkeletonResource {
  padding: 1rem;
  :global(.skeleton-lines) {
    border-radius: $border-radius;
    &:first-child {
      width: 90%;
      height: 2rem;
      margin-bottom: 0.5rem;
    }
    &:last-child {
      width: 60%;
      height: 0.75rem;
    }
  }
}
