.tabs {
  display: flex;
}

.tab {
  cursor: pointer;
  padding: 8px 0px;
  color: $gray-500;
  font-size: 18px;
  position: relative;
  margin: 0 12px;
  text-decoration: none;

  svg {
    width: 1rem;
    margin-left: 0.5rem;
    fill: $gray-500;
    transform: translateY(-2px);
  }

  &:hover {
    text-decoration: none;
    color: $gray-900;

    svg {
      fill: $gray-900;
    }
  }

  &:before {
    content: "";
    display: none;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: $blue;
    height: 4px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  &.is-active {
    color: $gray-900;
    &:before {
      display: block;
    }
    svg {
      fill: $gray-900;
    }
  }
}
