@import "~src/styles/_custom";

$xs-size: 16px;
$sm-size: 24px;
$md-size: 32px;
$lg-size: 40px;
$xl-size: 96px;
$xxl-size: 128px;

$xs-font-size: 6px;
$sm-font-size: 10px;
$md-font-size: 14px;
$lg-font-size: 16px;
$xl-font-size: 40px;
$xxl-font-size: 56px;

$xs-active-icon-size: 4px;
$sm-active-icon-size: 6px;
$md-active-icon-size: 8px;
$lg-active-icon-size: 8px;
$xl-active-icon-size: 12px;
$xxl-active-icon-size: 14px;

$xs-active-position: translate(2px, 2px);
$sm-active-position: translate(2px, 2px);
$md-active-position: translate(2px, 2px);
$lg-active-position: translate(0);
$xl-active-position: translate(-5px, -5px);
$xxl-active-position: translate(-10px, -10px);

.Avatar {
  border-radius: 50%;
  color: white;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background-color: $gray-500;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;

  .active {
    border-radius: 50%;
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: $primary;
    border: 2px solid white;
    box-sizing: content-box;
  }

  // color variants when no photo is available
  &.variant-1 {
    background-color: $blue;
  }
  &.variant-2 {
    background-color: $indigo;
  }
  &.variant-3 {
    background-color: $orange;
  }
  &.variant-4 {
    background-color: $yellow;
  }
  &.variant-5 {
    background-color: $teal;
  }
  &.variant-6 {
    background-color: $gray-500;
  }

  &.xs {
    width: $xs-size;
    height: $xs-size;
    font-size: $xs-font-size;
    .active {
      width: $xs-active-icon-size;
      height: $xs-active-icon-size;
      transform: $xs-active-position;
    }
  }
  &.sm {
    width: $sm-size;
    height: $sm-size;
    font-size: $sm-font-size;
    .active {
      width: $sm-active-icon-size;
      height: $sm-active-icon-size;
      transform: $sm-active-position;
    }
  }
  &.md {
    width: $md-size;
    height: $md-size;
    font-size: $md-font-size;
    .active {
      width: $md-active-icon-size;
      height: $md-active-icon-size;
      transform: $md-active-position;
    }
  }
  &.lg {
    width: $lg-size;
    height: $lg-size;
    font-size: $lg-font-size;
    .active {
      width: $lg-active-icon-size;
      height: $lg-active-icon-size;
      transform: $lg-active-position;
    }
  }
  &.xl {
    width: $xl-size;
    height: $xl-size;
    font-size: $xl-font-size;
    .active {
      width: $xl-active-icon-size;
      height: $xl-active-icon-size;
      transform: $xl-active-position;
    }
  }
  &.xxl {
    width: $xxl-size;
    height: $xxl-size;
    font-size: $xxl-font-size;
    .active {
      width: $xxl-active-icon-size;
      height: $xxl-active-icon-size;
      transform: $xxl-active-position;
    }
  }
}
