.ReactTable {
  font-size: 1rem;
  border: none;
  min-height: 60px;

  .rt-thead.-filters input,
  .rt-thead.-filters select {
    @extend .form-control;
    border-radius: 8px;
  }

  .rt-noData {
    z-index: 9;
  }

  .rt-td.dropdown-cell {
    overflow: unset;
    .dropdown-menu {
      z-index: 9999;
    }
  }

  .rt-thead.-header {
    box-shadow: none;
    border-bottom: 1px solid $gray-200;
  }

  .rt-thead .rt-tr {
    text-align: left;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 0.75rem;
    letter-spacing: 1px;
  }

  .rt-thead .rt-th,
  .rt-thead .rt-td,
  .rt-tbody .rt-td,
  .rt-thead.-filters .rt-th {
    border-right: none;
    line-height: 24px;
  }

  .rt-tr {
    align-items: center;
    &.-even {
      background-color: #f7f7f7;
    }
  }

  .rt-th,
  .rt-td {
    padding: 0.625rem 5px;
  }

  .rt-thead .rt-th.-sort-desc,
  .rt-thead .rt-th.-sort-asc {
    color: $primary;
    box-shadow: none;
    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 1rem;
      width: 16px;
      height: 16px;
    }
    &:before {
      content: "";
      display: block;
      position: absolute;
      bottom: -1px;
      left: 0;
      width: 100%;
      height: 3px;
      background-color: $primary;
    }
  }

  .rt-thead .rt-th.-sort-desc {
    &:after {
      background-image: url("data:image/svg+xml,%0A%3Csvg width='16px' height='16px' viewBox='0 0 16 16' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Symbols' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Icons-/-Custom-/-arrow_downward' transform='translate(-4.000000, -4.000000)' fill='%234CB258'%3E%3Cpath d='M19.295,11.295 C18.9056393,10.9056393 18.2734409,10.9059928 17.8820226,11.2967109 L13,16.17 L13,4.99592218 C13,4.44588955 12.5561352,4 12,4 L12,4 C11.4477153,4 11,4.44756998 11,4.99592218 L11,16.17 L6.12671086,11.2879774 C5.73640523,10.8969723 5.10485598,10.895144 4.71,11.29 L4.71,11.29 C4.31787783,11.6821222 4.3235178,12.3235178 4.70994663,12.7099466 L11.2900534,19.2900534 C11.6821461,19.6821461 12.3235178,19.6764822 12.7099466,19.2900534 L19.2900534,12.7099466 C19.6821461,12.3178539 19.6870753,11.6870753 19.295,11.295 L19.295,11.295 Z' id='Icon'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    }
  }
  .rt-thead .rt-th.-sort-asc {
    &:after {
      background-image: url("data:image/svg+xml,%0A%3Csvg width='16px' height='16px' viewBox='0 0 16 16' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Symbols' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Icons-/-Custom-/-arrow_downward' transform='translate(-4.000000, -4.000000)' fill='%234CB258'%3E%3Cpath d='M19.295,11.295 C18.9056393,10.9056393 18.2734409,10.9059928 17.8820226,11.2967109 L13,16.17 L13,4.99592218 C13,4.44588955 12.5561352,4 12,4 L12,4 C11.4477153,4 11,4.44756998 11,4.99592218 L11,16.17 L6.12671086,11.2879774 C5.73640523,10.8969723 5.10485598,10.895144 4.71,11.29 L4.71,11.29 C4.31787783,11.6821222 4.3235178,12.3235178 4.70994663,12.7099466 L11.2900534,19.2900534 C11.6821461,19.6821461 12.3235178,19.6764822 12.7099466,19.2900534 L19.2900534,12.7099466 C19.6821461,12.3178539 19.6870753,11.6870753 19.295,11.295 L19.295,11.295 Z' id='Icon' transform='translate(12.000000, 12.000000) rotate(-180.000000) translate(-12.000000, -12.000000) '%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
    }
  }

  .rt-thead .rt-th:last-child,
  .rt-thead .rt-td:last-child,
  .rt-tbody .rt-td:last-child,
  .rt-thead.-filters .rt-th:last-child {
    text-align: right;
  }

  .rt-noData {
    background: rgba(78, 78, 78, 0.62);
    border-radius: 100px;
    font-weight: bold;
    font-size: 0.75rem;
    color: white;
    text-transform: uppercase;
    letter-spacing: 1px;
  }

  .-loading.-active > div {
    transform: translateY(-50%);
  }
}

.table-footer {
  display: flex;
  justify-content: space-between;
  margin: 1rem 0 0;

  &-pagination {
    display: flex;
  }
}
.pagination {
  display: flex;

  &-btn {
    background-color: transparent;
    border: none;
    padding: 0 0;
    height: 48px;
    width: 48px;
    cursor: pointer;
    border-radius: 3px;

    &:hover,
    &:focus {
      background-color: $gray-200;
    }

    &:disabled {
      pointer-events: none;
      opacity: 0.5;
    }
  }
  &-input {
    @extend .form-control;
    width: 65px;
    margin: 0 8px;
  }
  &-page-info {
    display: flex;
    align-items: center;
    margin-right: 8px;

    &.disabled {
      pointer-events: none;
    }
  }
  &-size-btn {
    background-color: transparent;
    border: $input-border-width solid $input-border-color;
    padding: $input-btn-padding-y $input-btn-padding-x;
    border-radius: $border-radius;

    &:hover,
    &:focus {
      background-color: $gray-200;
    }
  }
}

.rthfc.-sp {
  .rt-tr.-even {
    .rt-td {
      background-color: #f7f7f7;
    }
  }

  .rthfc-td-fixed-left-last,
  .rthfc-th-fixed-left-last {
    overflow: visible;

    & + .rt-th,
    & + .rt-td {
      padding-left: 30px !important;
    }
  }

  .rthfc-td-fixed-left-last {
    &:before {
      content: "";
      position: absolute;
      left: 100%;
      top: 0;
      height: calc(100% + 1px);
      width: 30px;
      background: linear-gradient(90deg, #00000015, transparent);
    }
  }

  .rthfc-th-fixed-left-last .rt-resizable-header-content,
  .rt-thead.-filters .rthfc-th-fixed-left-last {
    &:before {
      content: "";
      position: absolute;
      left: 100%;
      top: 0;
      height: calc(100% + 1px);
      width: 30px;
      background: linear-gradient(90deg, #00000015, transparent);
    }
  }

  .rt-thead.-header {
    .rthfc-th-fixed-left-last {
      .rt-resizable-header-content:after {
        content: "";
        position: absolute;
        left: 100%;
        top: 0px;
        height: 50%;
        width: 30px;
        background: linear-gradient(0deg, transparent, #ffffff);
      }
    }
  }

  .rt-tr-group:last-child {
    .rthfc-td-fixed-left-last {
      &:after {
        content: "";
        position: absolute;
        left: 100%;
        bottom: 0;
        height: 50%;
        width: 30px;
        background: linear-gradient(180deg, transparent, #ffffff);
      }
    }
  }

  .rt-thead.-filters {
    .rthfc-th-fixed {
      min-height: 54px;
      // &-left-last:after {
      //   height: 100%;
      // }
    }
  }
}
