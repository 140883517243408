.status-pill {
  padding: 6px 1rem 4px;
  border-radius: 100px;
  font-weight: bold;
  color: white;
  font-size: 0.875rem;
  display: inline-block;
  background-color: $gray-500;
  &.danger {
    background-color: $danger;
  }
}
