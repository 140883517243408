@import "~src/styles/_custom";

.MenuIcon {
  margin-right: 1rem;
  :global(g) {
    fill: white;
  }
  &:focus {
    outline: none;
  }
  &_Disabled {
    pointer-events: none;
  }
}

.MenuContainer {
  border-right: 1px solid rgba(0, 0, 0, 0.125);
  z-index: 9999;
  left: -350px;
  transition: left 0.5s ease-in-out;
  background-color: white;
  top: 64px;
  height: calc(100vh - 64px);
  width: 300px;
  position: fixed;

  &_Open {
    left: 0;
  }

  &:after {
    content: "";
    position: absolute;
    left: 100%;
    width: 50px;
    top: 0;
    height: 100%;
    background-color: transparent;
    background: transparent;
    background: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0.1) 0%,
      rgba(255, 255, 255, 0) 100%
    );
  }
}

.Menu {
  padding: 0.5rem 0;
  overflow-y: scroll;
  height: 100%;
}

.ItemContainer {
  background-color: transparent;
  transition: background-color 0.2s ease-in-out;
  &_Open {
    background-color: rgba(0, 0, 0, 0.05);
  }
}

.Item {
  width: 100%;
  border: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.75rem 1.75rem;
  color: $gray-900;

  &:hover {
    background-color: $gray-200;
  }
  &:focus {
    outline: none;
  }
}

.ItemIcon {
  width: 48px;
  text-align: left;

  :global(svg path) {
    fill: $gray-900;
  }
}

.ItemLabel {
  text-align: left;
  flex-grow: 1;
}

.ItemDropdown {
}
