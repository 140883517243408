.Container {
  position: relative;
  width: 100%;
  & * {
    box-sizing: border-box;
  }

  &_Body {
    overflow-y: scroll;
    padding-bottom: 500px;
  }
}

.ResourceWrapper {
  border-bottom: 2px solid #eee;
  border-right: 2px solid #eee;
}

.Aside {
  position: absolute;
  background-color: white;
  left: 0px;
  top: 0px;
}

.HeadBody {
  position: relative;
  overflow-x: scroll;
  width: 100%;
}

.Body {
  position: relative;
  overflow-x: scroll;
  width: 100%;
}

.Row {
  position: relative;
  background-color: #f5faff;

  &_Even {
    background-color: white;
  }
}

.RowEvents {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.HeadColWrapper {
  flex-grow: 1;
  flex-shrink: 0;
  border-left: 2px solid #eee;
  border-bottom: 2px solid #eee;
}

.RowCols {
  display: flex;
  height: 100%;
  width: 100%;
}

.Col {
  flex-grow: 1;
  flex-shrink: 0;
  border-left: 2px solid #eee;
  border-bottom: 2px solid #eee;
}

.AsideCol {
  background-color: #f4f8fb;
  flex-grow: 1;
  flex-shrink: 0;
  border-bottom: 2px solid #eee;
  border-right: 2px solid #eee;
}

.Events {
}
