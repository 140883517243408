label {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75rem;
  color: $gray-900;
  letter-spacing: 0.7px;
}

.input-group-text {
  font-weight: bold;
  border-radius: 0;
  font-size: 0.875rem;
  color: #666666;
  letter-spacing: 0.7px;
  padding: $input-btn-padding-y $input-btn-padding-x;
}

.prependInput,
.input-group-prepend .input-group-text {
  border-left: 2px solid $gray-400;
  border-top: 2px solid $gray-400;
  border-bottom: 2px solid $gray-400;
  border-top-left-radius: $border-radius;
  border-bottom-left-radius: $border-radius;
}

.appendInput,
.input-group-append .input-group-text {
  border-right: 2px solid $gray-400;
  border-top: 2px solid $gray-400;
  border-bottom: 2px solid $gray-400;
}

.form-control {
  border: 2px solid $gray-400;
}

.input-group > .form-control.is-invalid:not(:last-child) {
  border-top-right-radius: $border-radius;
  border-bottom-right-radius: $border-radius;
  border-color: $input-border-color;
}

input,
textarea {
  color: #1a1a1a !important;
}

input::placeholder {
  color: #c0c0c0 !important;
}

.invalid-prepend {
  :global .input-group-text {
    border-top: 2px solid #f20909;
    border-left: 2px solid #f20909;
    border-bottom: 2px solid #f20909;
  }
}

legend {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75rem;
  letter-spacing: 0.7px;
}

.form-check-label {
  font-weight: normal;
  font-size: 1rem;
  text-transform: none;
  line-height: 1.85rem;
}
