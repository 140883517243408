@import "~src/styles/_custom";

.ViolationBadge {
  position: absolute;
  right: 0px;
  top: 0.875rem;
  border-radius: $border-radius;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  background-color: $red;
  color: white;
  cursor: pointer;
  padding: 2px 5px;

  &_Acknowledged {
    background-color: $gray-300;
    color: $gray-500;
  }
}
