@import "~src/styles/_custom";

.ShiftCalendarContainer {
  position: relative;
  flex: 0 0 43%;
  margin-left: 2%;
}
.ShiftCalendar {
  position: absolute;
  z-index: 3;
  top: calc(100% + 10px);
  width: 100%;
  min-width: 320px;

  &_Center {
    right: 50%;
    transform: translateX(50%);
  }

  &_Right {
    right: 0;
  }
}

.DateTimeSection {
  flex: 0 0 46%;
  display: flex;
  margin-bottom: 1rem;
}

.DateTimeTo {
  flex: 0 0 8%;
  margin-bottom: 1rem;
  text-align: center;
}

.DateTimeSectionTime {
  flex: 0 0 55%;
}

.DateTimeLabel {
  flex: 0 0 46%;
}

.ShiftDropdownContainer {
  position: relative;

  &_Disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}
.ShiftDropdownValue {
  padding: 0.5rem 1rem;
  border: 2px solid #deeffd;
  background-color: #deeffd;
  border-radius: 100px;
  color: #339df6;
  font-weight: bold;
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  position: relative;
  :global(path) {
    fill: $blue;
  }
}
.ShiftDropdown {
  position: absolute;
  border-radius: 4px;
  z-index: 3;
  box-shadow: $box-shadow;
  top: calc(100% + 10px);
  width: 100%;
  min-width: 300px;
  left: 0;
  max-height: 250px;
  overflow: scroll;
}

.ShiftFormInput {
  padding: 0.5rem;
  border-radius: 4px;
  width: 100%;
  font-size: 1rem;
  text-align: left;
  border: 2px solid $gray-200;
  &:focus,
  &:active,
  &_Focused {
    background-color: #f5faff;
    outline: none;
    box-shadow: none;
  }
}

.TransitionWrapper {
  position: relative;
}

.TransitionItem {
  position: relative;
  width: 100%;
  transition: all 300ms ease-in-out;

  &:global(.enter) {
    position: absolute;
    opacity: 0;
    transform: translateX(100px);
  }

  &:global(.enter-active) {
    position: relative;
    opacity: 1;
    transform: translateX(0px);
  }

  &:global(.exit) {
    transform: translateX(0px);
    position: relative;
    opacity: 1;
  }

  &:global(.exit-active) {
    opacity: 0;
    position: absolute;
    transform: translateX(-100px);
  }
}

.ShiftActionButton {
  cursor: pointer;
}
