.empty-content-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  &.height-sm {
    height: 25rem;
  }
  &.height-lg {
    height: 90vh;
  }
  p,
  h2 {
    margin: 10px 0 4px;
  }
  p {
    color: #999;
  }

  svg {
    width: 4rem;
    height: 4rem;
    margin-bottom: 1rem;
  }
}
