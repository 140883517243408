.DailyAvailability {
  height: 18px;
  padding: 0 0.5rem;
  position: relative;
  width: 100%;
  font-weight: 900;
  font-size: 0.625rem;
  letter-spacing: 1px;
  line-height: 18px;
  transition: height 0.1s;

  &_PartiallyAvailable {
    background-color: #fffbec;
    color: #927500;
  }

  &_Expandable {
    cursor: pointer;

    &Open {
      z-index: 2;
    }
    &:hover {
      background-color: mix(black, #fffbec, 5);
    }
  }

  &_Unavailable {
    background-image: linear-gradient(
      135deg,
      #fff7f5 25%,
      #fff 25%,
      #fff 50%,
      #fff7f5 50%,
      #fff7f5 75%,
      #fff 75%,
      #fff 100%
    );
    background-size: 16.97px 16.97px;
    color: #7e2008;
    height: 100%;
  }
}

.HourlyAvailability {
  height: 100%;
  width: calc(100% + 2px);
  position: absolute;
  border-right-width: 2px;
  border-right-style: solid;

  &_Available {
    background-color: #e0f1e0;
    border-right-color: #cde4ce;
  }

  &_Unavailable {
    background-color: #ffe4e3;
    border-right-color: #eac7c6;
  }
}
