@import "~src/styles/custom";

.FilterList {
  overflow-y: auto;
  max-height: 250px;
  margin: 0 -1rem;
  border-bottom-left-radius: $border-radius;
  border-bottom-right-radius: $border-radius;
}

.FilterSectionHead {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: 1.75rem;
  padding: 0.5rem 0;

  :global(h5) {
    flex: 1 0 auto;
  }

  &_Scheduled {
    padding-right: 1rem;
    padding-left: 1rem;
    margin: 0 -1rem;
    border-bottom: 1px solid $gray-200;
  }
}
