.modal-backdrop {
  backdrop-filter: blur(2px);
}

.modal-dialog.jumbo {
  max-width: 80%;
  margin: 2rem auto;
  height: calc(100% - 4rem);
  .modal-content {
    height: 100%;
  }
}

.modal-dialog.light-bg {
  .modal-content {
    background-color: $gray-100;
  }
}

.modal {
  &_enter {
    opacity: 0;
    transform: translateY(20px);
  }
  &_enterActive {
    transition: opacity 2000ms ease-in-out, transform 2000ms ease-in;
    opacity: 1;
    transform: translateY(0px);
  }

  &_exit {
    opacity: 1;
    transform: translateY(0px);
  }
  &_exitActive {
    transform: translateY(-50px);
    opacity: 0;
    transition: opacity 2000ms ease-in-out, transform 2000ms ease-in;
  }
}
