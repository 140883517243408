@import "~src/styles/_custom";

.DemandForecast {
  padding-left: 198px;
  position: relative;

  &_Main {
    overflow-x: scroll;
  }

  &_Aside {
    position: absolute;
    width: 200px;
    left: 0;
    height: 100%;
    top: 0px;
  }

  &_AsideCol {
    padding: 0.5rem 1rem 0.5rem 0;
  }
}

.ListItem {
  font-size: 0.75rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.25rem 0.5rem;
  &_Label {
    font-weight: bold;
  }
}
