$cancel-icon-size: 1em;

.x-icon {
  font-size: 1.5rem;
  width: $cancel-icon-size;
  height: $cancel-icon-size;
  position: relative;
  border-radius: 100px;
  cursor: pointer;

  &:before,
  &:after {
    content: "";
    position: absolute;
    width: $cancel-icon-size / 4 * 3;
    height: $cancel-icon-size / 10;
    background-color: black;
    border-radius: 2px;
    top: 50%;
  }

  &:before {
    transform: translateY(-50%) rotate(45deg);
    left: $cancel-icon-size / 8;
  }

  &:after {
    transform: translateY(-50%) rotate(-45deg);
    right: $cancel-icon-size / 8;
  }
}
