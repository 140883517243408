@import "~src/styles/_custom";

$container-height: 20px;
$container-width: 40px;
$circle-radius: 8px;

.SwitchToggle {
  height: $container-height;
  width: $container-width;
  cursor: pointer !important;
  user-select: none !important;
  position: relative !important;
  display: inline-block;
}

.SwitchToggle:before,
.SwitchToggle:after {
  content: "";
  left: 0;
  position: absolute !important;
}

.SwitchToggle:before {
  height: inherit;
  width: inherit;
  border-radius: $container-height / 2;
  will-change: background;
  background: $gray-600 !important;
  transition: background 0.4s 0.3s ease-out;
}

.SwitchToggle:after {
  top: 2px;
  height: $circle-radius * 2;
  width: $circle-radius * 2;
  border-radius: $circle-radius;
  background: #ffffff !important;
  will-change: transform;
  transform: translateX(2px);
  transition: transform 0.4s ease-out;
}

.SwitchToggle_On:before {
  background: $primary !important;
}

.SwitchToggle_On:after {
  transform: translateX(22px);
}
