.page-body {
  padding: 1.5rem 1.5rem 8rem;
  width: 100%;
  display: block;
}

.page-top {
  position: relative;
  background-color: white;
  padding: 3rem 1.5rem 0 1.5rem;
  box-shadow: 0px 0px 1px 0 rgba(9, 30, 66, 0.31);

  &--simple {
    background-color: transparent;
    box-shadow: none;
  }

  .page-back {
    position: absolute;
    top: 1rem;
    left: 1.5rem;
    text-decoration: none;
    font-size: 0.75rem;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 0.7px;

    svg {
      width: 0.75rem;
      height: 0.75rem;
      transform: translateY(-1px);
      margin-right: 0.5rem;
      path {
        fill: $blue;
      }
    }

    &:hover svg path {
      fill: $link-hover-color;
    }
  }

  h1 {
    flex: 1 1 auto;
  }
}

.page-header {
  display: flex;
  align-items: flex-start;
  padding-bottom: 1.5rem;

  .badge {
    font-size: 1rem;
    margin-top: 0.5rem;
  }
}

.page-title {
  flex: 1 1 auto;
  h1 {
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
  }
  p {
    margin-bottom: 0;
    font-size: 1.25rem;
    max-width: 700px;
  }
}

.page-toolbar {
  display: flex;
  align-items: flex-end;
}

.page-tabs {
  flex-grow: 1;
}

.page-shell {
  margin-left: 64px;
}

.page-actions {
  display: flex;
  align-items: center;
}

.content-wrapper {
  padding: 32px 48px;
  background: white;
  border-radius: 4px;
  box-shadow: 2px 2px 15px transparentize(#37536b, 0.9);
}

.aside-page-sidebar {
  position: fixed;
  padding: 2.5rem;
  background-color: white;
  right: 0;
  top: 0;
  width: calc(40% - 2rem);
  height: 100%;
}

.aside-page-main {
  width: 60%;
}
