@keyframes pop-in {
  0% {
    transform: translateX(-20px);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translateY(0);
  }
}

.popup-container {
  z-index: 1002;
  scroll-behavior: auto;
}

.popup {
  animation-name: pop-in;
  animation-duration: 0.5s;
  box-shadow: $box-shadow-lg;
  border: 0;

  .card-body {
    padding: 0.5rem 1rem 1rem;
  }
  .card-header {
    padding: 0.25rem 0.5rem 0.25rem 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: none;
    background: none;

    h5 {
      padding-left: 0.5rem;
      margin-bottom: 0;
    }
  }
}
