.dropzone-container {
  margin: 0 auto;
}

.dropzone {
  min-height: 350px;
  padding: 2rem;
  text-align: center;
  display: block;
  border: 2px dashed $gray-400;
  transition: all 0.3s ease-in-out;
  color: white;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &-content {
  }

  &.drag-active,
  &:focus {
    outline: none;
    background-color: rgba(255, 255, 255, 0.1);
    border-color: $primary;
  }
}

.input-dropzone {
  cursor: pointer;

  &-field {
    @extend .form-control;
    position: relative;

    span {
      top: 50%;
      position: absolute;
      left: 0.75rem;
      transform: translateY(-50%);
      color: $gray-900;
    }

    svg {
      position: absolute;
      top: 50%;
      right: 1rem;
      transform: translateY(-50%);
    }
  }

  &.drag-active,
  &:focus {
    outline: none;

    .input-dropzone-field {
      outline: none;
      background-color: rgba(255, 255, 255, 0.1);
      border-color: $blue;
    }
  }
}
