.WeeklyForecastTable {
  padding-left: 200px;
  position: relative;
  font-size: 0.75rem;

  &_Main {
    display: flex;
    overflow-x: scroll;
  }

  &_Aside {
    position: absolute;
    width: 200px;
    left: 0;
    top: 0;
  }

  &_AsideCol {
    padding: 0.5rem 0;
    border-right: 2px solid #eee;
  }

  &_Col {
    flex: 1 0 150px;
    padding: 0.5rem 0;
    border-left: 2px solid #eee;
  }
}

.ListItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.25rem 0.5rem;
  &_Label {
    font-weight: bold;
  }
}
