@keyframes infinite-spinning {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

$size: 80px;

.spinner {
  width: $size;
  height: $size;
  display: block;
  margin: 0 auto;
}

.spinner-inner {
  transform-origin: 40px 40px;
  animation: infinite-spinning 1.25s infinite linear;
}

.spinner-spinny {
  fill: $primary;

  .light & {
    fill: white;
  }
}

.spinner-ring {
  fill: white;

  .light & {
    fill: rgba(255, 255, 255, 0.5);
  }
}
