.DateRangePickerInput {
  background: transparent;
}

.DateInput {
  width: 120px;
  background: transparent;
}

.DateInput_input {
  font-size: 18px;
  color: $gray-500;
  background: transparent;
  text-align: right;
}

.DateInput_input__focused {
  border-bottom: none;
  color: $blue;
}

.CalendarDay__selected {
  &,
  &:hover,
  &:active {
    background: mix(black, $blue, 20%);
    border-color: mix(black, $blue, 20%);
  }
}
.CalendarDay__selected_span {
  background: $blue;
  border-color: mix(black, $blue, 20%);

  &:active,
  &:hover {
    background: mix(black, $blue, 20%);
    border-color: mix(black, $blue, 20%);
  }
}

.DayPickerKeyboardShortcuts_show__bottomRight::before {
  border-right-color: $green;
}
