@import "~src/styles/_custom";

.DropdownListItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  :global(svg path) {
    fill: $blue;
  }
}
