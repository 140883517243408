@import "~src/styles/_custom";
$size: 2rem;

.StatefulBtn {
  position: relative;
  overflow: hidden;

  :global svg {
    width: $size;
    height: $size;
    opacity: 0;
    position: absolute;
    top: -50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: all 0.2s ease-in-out;
  }

  .Contents {
    opacity: 1;
    transition: all 0.2s ease-in-out;
  }

  &.isLoading {
    pointer-events: none;
    .Contents {
      opacity: 0;
    }

    :global svg {
      opacity: 1;
      top: 50%;
    }
  }

  &:global(.btn-outline-primary) {
    :global(.spinner-ring) {
      fill: $gray-300;
    }
    :global(.spinner-spinny) {
      fill: $primary;
    }
  }
}
