.badge-pill {
  padding: 0.5em 1em 0.4em;
}

.badge {
  font-size: 0.875em;
  font-weight: bold;
  &-success {
    background-color: #e2f2e4;
    color: $primary;
  }
  &-warning {
    background-color: #ffe7dc;
    color: $orange;
  }
  &-danger {
    background-color: #fde0e1;
    color: $red;
  }
  &-info {
    background-color: #deeffd;
    color: $blue;
  }
  &-secondary {
    background-color: $gray-500;
    color: white;
  }
}
