.Button {
  width: 0.5rem;
  margin-left: 3px;
  transform: translateY(-1px);
  :global(path) {
    fill: inherit;
  }

  &_LightArrow {
    :global(path) {
      fill: white;
    }
  }
}
