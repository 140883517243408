.btn {
  text-transform: uppercase;
  letter-spacing: 1px;
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: $gray-500;
  background-color: $gray-400;
  border-color: $gray-400;
}

.input-group-prepend .btn,
.input-group-append .btn {
  padding: 0.25rem 1rem;
}

.btn-outline-secondary {
  border-color: $gray-200;
  color: $gray-900;

  &:hover,
  &:focus {
    background-color: $gray-200;
    border-color: $gray-200;
  }
}

.dropdown {
  cursor: pointer;
}

.btn-white {
  @include button-variant(
    white,
    darken(white, 7.5%),
    darken(white, 10%),
    lighten(white, 5%),
    lighten(white, 10%),
    darken(white, 30%)
  );
}

.btn-outline-white {
  @include button-outline-variant(white, #222222, lighten(white, 5%), white);
}

.circle-btn {
  width: 3rem;
  cursor: pointer;
  height: 3rem;
  background-color: transparent;
  transition: background-color 0.2s ease-in-out;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 100px;

  &:hover {
    background-color: transparentize($gray-200, 0.75);
  }

  &:before {
    content: "";
    display: none;
    width: 0.625rem;
    height: 0.625rem;
    position: absolute;
    top: 6px;
    right: 6px;
    border-radius: 50%;
    background-color: $red;
  }
  &.has-alert {
    &:before {
      display: block;
    }
  }
}
