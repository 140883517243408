.table {
  thead {
    th {
      text-align: left;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 0.75rem;
      letter-spacing: 1px;
      border: none;
    }
  }
  td {
    border-top: 1px solid rgba(0, 0, 0, 0.05);
  }
  tr:nth-child(even) {
    background-color: #f7f7f7;
  }

  th:last-child,
  td:last-child {
    text-align: right;
  }
}
