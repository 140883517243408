.link {
  color: $blue;
  text-decoration: underline;
  cursor: pointer;
  transition: color 0.3s ease-in-out;

  &:hover,
  &:focus {
    color: darken($blue, 10%);
  }
}
