@import "~src/styles/_custom";

.DrawerOverlay {
  &_Active {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
    z-index: 1000;
  }
}

.DrawerContainer {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 0;
  transform: translateY(100%);
  transition: transform 0.3s ease-in-out;
  z-index: 1001;

  &_Open {
    transform: translateY(0);

    .DrawerAction {
      border: 4px solid $gray-300;
      border-bottom: none;
      background-color: white;
    }
  }
}

.DrawerAction {
  background-color: white;
  height: 50px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom: none;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -46px);

  &:focus {
    outline: none;
    box-shadow: none;
    background-color: white;
  }
}
.DrawerContentContainer {
  background-color: white;
  box-shadow: $box-shadow-lg;
  overflow: hidden;
  border-top: 4px solid $gray-300;
  // height: 200px;
}
