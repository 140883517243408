.MenuIcon {
  margin-right: 1rem;

  :global(g) {
    fill: white;
  }
}

.Brand {
  margin-right: auto;
  transform: translateY(-2px);
}
